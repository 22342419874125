//スライダー　導入事例

$(function(){
  slider_cases();

  //スライダー　導入事例
  function slider_cases() {

    //スライダーの総数をdata-countに
    $('[data-js="slider-cases"]').on('init', function(event, slick) {
      $(this).data('count',slick.slideCount);
    });

    $('[data-js="slider-cases"]').slick({
      centerMode: true,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 5000,
      speed: 1000,
      slidesToShow: 3,
      arrows: true,
      prevArrow: '<div class="c-slider__control-item c-slider__control-item--prev"><button class="c-slider__control-btn"><i class="fas fa-chevron-left"></i></button></div>',
      nextArrow: '<div class="c-slider__control-item c-slider__control-item--next"><button class="c-slider__control-btn"><i class="fas fa-chevron-right"></i></button></div>',
      dots: true,
      centerPadding: 0,
      responsive: [
        {
          breakpoint: 1551,
          settings: {
            slidesToShow: 1,
            centerPadding: '350px',
          }
        },
        {
          breakpoint: 1201,
          settings: {
            slidesToShow: 1,
            centerPadding: '250px',
          }
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 1,
            centerPadding: '200px',
          }
        },
        {
          breakpoint: 901,
          settings: {
            slidesToShow: 1,
            centerPadding: 0,
          }
        },
      ],
    });

    //イベント
    $('[data-js="slider-cases"]').on('beforeChange', function(slick, currentSlide, nextSlide){
      var slideLength = $(this).data('count');
      var cloneFirst = slideLength - 1;

      //横幅1511px以下で一巡するときに拡大がスムーズにいかないのを調整
      if ( nextSlide == cloneFirst ) {
        $('[data-slick-index="' + slideLength + '"]').addClass('cloneCurrent');
      } else {
        $('[data-slick-index="' + slideLength + '"]').removeClass('cloneCurrent');
      }
    });

    //コントローラー
    $('.p-cases .c-slider__control-item--prev > a').on('click', function(e) {
      e.preventDefault();
      $('[data-js="slider-cases"]').slick('slickPrev');
    });
    $('.p-cases .c-slider__control-item--next > a').on('click', function(e) {
      e.preventDefault();
      $('[data-js="slider-cases"]').slick('slickNext');
    });
  }

});